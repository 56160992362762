import React from 'react';
import Box from '@webAtoms/Box/Box';
import UpNext from '@webMolecules/UpNext/UpNext';
import * as Route from '@routes';
import type { OurProcessSection } from './shared';
import { Steps } from './Sections/Steps';
import { Specialise } from './Sections/Specialise';
import { Dream } from './Sections/Dream';

import * as styles from './our_process.module.scss';

export interface OurProcessProps {
  title: string;
  sections: OurProcessSection[];
}

const OurProcess: React.FC<OurProcessProps> = ({ title, sections }) => {
  const getSection = (section: OurProcessSection) => {
    switch (section.type) {
      case 'steps':
        return <Steps {...section} />;
      case 'specialise':
        return <Specialise {...section} />;
      case 'dream':
        return <Dream {...section} />;

      default:
        return null;
    }
  };

  return (
    <Box className={styles.ourProcess}>
      {sections.map((section, index) => (
        <React.Fragment key={index}>{getSection(section)}</React.Fragment>
      ))}

      <UpNext
        links={[
          {
            label: 'Our approach',
            href: Route.ourApproach(),
          },
          {
            label: 'Our values',
            href: Route.ourValues(),
          },
        ]}
      />
    </Box>
  );
};

export default OurProcess;
