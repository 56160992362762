// extracted by mini-css-extract-plugin
export var column = "our_process-module--column--xSafQ";
export var columns = "our_process-module--columns--VIUTZ";
export var content = "our_process-module--content--5635u";
export var dream = "our_process-module--dream--ZBCq3";
export var image = "our_process-module--image--pmRsy";
export var imageFullHeight = "our_process-module--imageFullHeight--u77sz";
export var inner = "our_process-module--inner--5k8nG";
export var ourProcess = "our_process-module--ourProcess--mJ0Qc";
export var section = "our_process-module--section--U0Hy+";
export var specialise = "our_process-module--specialise--fjP3W";
export var steps = "our_process-module--steps--zORlv";