import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import Base from '@webLayouts/Base/Base';
import OurProcess from '@webPages/About/OurProcess/OurProcess';
import { PageOurProcessQuery } from '@generated/GraphqlTypes';
import type { OurProcessSection } from '@webPages/About/OurProcess/shared';

const OurProcessReportPage: React.FC<PageProps<PageOurProcessQuery>> = ({
  data,
}) => {
  const title = data?.ourProcess?.frontmatter?.title ?? '';

  const seoTitle = data?.ourProcess?.frontmatter?.seoTitle ?? undefined;
  const seoDescription =
    data?.ourProcess?.frontmatter?.seoDescription ??
    data?.ourProcess?.excerpt ??
    undefined;

  const image =
    data?.ourProcess?.frontmatter?.heroImage?.publicURL ?? undefined;

  const sections = (data?.ourProcess?.frontmatter?.sections?.map(parent => ({
    type: parent?.type ?? '',
    title: parent?.title ?? '',
    body: parent?.body ?? null,
    image: parent?.image ?? null,
    download: parent?.download ?? null,

    sections: (parent?.sections ?? []).map(child => ({
      title: child?.title ?? '',
      body: child?.body ?? null,
      image: child?.image ?? null,
      download: child?.download ?? null,

      sections: (child?.sections ?? []).map(grandchild => ({
        title: grandchild?.title ?? '',
        body: grandchild?.body ?? null,
        image: grandchild?.image ?? null,
        download: grandchild?.download ?? null,
      })),
    })),
  })) ?? []) as OurProcessSection[];

  return (
    <Base
      theme="light"
      title={seoTitle}
      description={seoDescription}
      image={image}
    >
      <OurProcess title={title} sections={sections} />
    </Base>
  );
};

export default OurProcessReportPage;

export const query = graphql`
  query PageOurProcess {
    ourProcess: markdownRemark(
      fields: { collection: { eq: "pages" } }
      frontmatter: { page: { eq: "our-process" } }
    ) {
      html
      excerpt
      frontmatter {
        title
        seoTitle
        seoDescription

        sections {
          type
          title
          body
          download {
            publicURL
          }
          image {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 800)
            }
          }

          sections {
            title
            body
            download {
              publicURL
            }
            image {
              publicURL
              childImageSharp {
                gatsbyImageData(width: 800)
              }
            }

            sections {
              title
              body
              download {
                publicURL
              }
              image {
                publicURL
                childImageSharp {
                  gatsbyImageData(width: 800)
                }
              }
            }
          }
        }

        heroImage {
          publicURL
          childImageSharp {
            gatsbyImageData(
              width: 2000
              # placeholder: BLURRED
              # quality: 70
              # formats: [AUTO, WEBP, AVIF, JPG]
            )
          }
        }
      }
    }
  }
`;
