import * as React from 'react';

import Box from '@webAtoms/Box/Box';
import type { OurProcessSection } from '../shared';
import * as styles from '../our_process.module.scss';
import Container from '@webAtoms/Container/Container';
import { render } from '@helpers/markdown';

export const Steps = ({ sections, title }: OurProcessSection) => {
  return (
    <Box className={styles.steps}>
      <Container>
        <Box className={styles.inner}>
          <h2>{title}</h2>

          <Box className={styles.content}>
            {sections.map((section, index) => (
              <Box key={index} className={styles.section}>
                <h3>{section.title}</h3>

                <Box className={styles.columns}>
                  {section.sections.map((subSection, index) => (
                    <Box key={index} className={styles.column}>
                      {subSection.image && (
                        <img
                          className={styles.image}
                          src={subSection.image.publicURL}
                          alt={title}
                        />
                      )}
                      <h4>{subSection.title}</h4>
                      <Box htmlContent={render(subSection.body ?? '')} />
                    </Box>
                  ))}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
