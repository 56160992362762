import * as React from 'react';

import Box from '@webAtoms/Box/Box';
import type { OurProcessSection } from '../shared';
import * as styles from '../our_process.module.scss';
import Container from '@webAtoms/Container/Container';
import { render } from '@helpers/markdown';

export const Specialise = ({ sections, image, title }: OurProcessSection) => {
  return (
    <Box className={styles.specialise}>
      <Container>
        <Box className={styles.inner}>
          <Box className={styles.content}>
            <Box>
              <h2>{title}</h2>

              <Box className={styles.columns}>
                {sections.map((section, index) => (
                  <Box key={index} className={styles.column}>
                    <h4>{section.title}</h4>
                    <Box htmlContent={render(section.body ?? '')} />
                  </Box>
                ))}
              </Box>
            </Box>

            <Box className={styles.image}>
              <div
                className={styles.imageFullHeight}
                style={{ backgroundImage: `url(${image?.publicURL})` }}
              />
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
